/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';
import "/assets/styles/app.css";
import "./js/app.js";
import './bootstrap';
import { createApp } from 'vue';
import FooterComponent from './components/FooterComponent.vue';

import nav from './components/n-vatrisa.vue';
import navMobile from './components/n-vatrisa-mobile.vue';
import bannerEstatico from './components/b-estatico.vue';
import navEstatico from './components/n-estatico.vue';
import navEstaticoM from './components/n-estatico-mobile.vue';
import bannerEstaticoM from './components/b-estatico-mobile.vue';


const app = createApp({
});

app.component('footer-component', FooterComponent);
app.component('nav-vatrisa', nav);
app.component('nav-vatrisa-m', navMobile);
app.component('b-estatico', bannerEstatico);
app.component('n-estatico', navEstatico);
app.component('n-estatico-mobile', navEstaticoM);
app.component('b-estatico-mobile', bannerEstaticoM);

app.mount('#app');

