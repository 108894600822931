<template>
    
    <div class="container-fluid custom-gray-banner d-none d-md-block">
        <div class="container-fluid custom-second custom-text-center">
            <div class="row row-cols-4">
                <div class="col"><p class="custom-banner-text">Logística&nbsp;&nbsp;<img src="/img/icons/icon-list.svg" alt="List Icon" class="custom-icons-gray-icon1"></p></div>
                <div class="col"><p class="custom-banner-text">Distribución&nbsp;&nbsp;<img src="/img/icons/icon-truck.svg" alt="Truck Icon" class="custom-icons-gray-icon"></p></div>
                <div class="col"><p class="custom-banner-text">Impulso comercial&nbsp;&nbsp;<img src="/img/icons/icon-talking.svg" alt="Talking Icon" class="custom-icons-gray-icon"></p></div>
                <div class="col"><p class="custom-banner-text">Atención personalizada&nbsp;&nbsp;<img src="/img/icons/icon-headphone.svg" alt="Headphone Icon" class="custom-icons-gray-icon2"></p></div>
            </div>
        </div>
    </div>
    

 </template>
 <script>
    export default {
        name: "b-estatico"
    }
 </script>
 <style scoped>
 </style>
