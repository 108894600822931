<template>
    <div class="container-fluid menu-estaico d-none d-md-block" style="z-index: 1001;">
        <div class="container-fluid d-flex justify-content-between align-items-center">
            <!-- Contenedor para la imagen de WhatsApp -->
            <div class="nav-left">
                <a href="/">
                    <img src="/img/vatrisa-logo-black.svg" alt="Logo Scrolled" class="logo-estatico">
                </a>      
            </div>
            
            <!-- Contenedor para los enlaces, centrados -->
            <div class="nav-links d-flex justify-content-center align-items-center">
                <a class="nav-link-estatico" href="/">Inicio</a>
                <div class="nav-link dropdown">
                    <a href="#" class="nav-link-estatico">Productos</a>
                    <!-- SubmenÃº Desplegable -->
                    <ul class="dropdown-menu">
                        <li v-for="producto in productos" :key="producto.slug">
                        <a :href="`/producto/${producto.slug}`">{{ producto.titulo_producto }}</a>
                        </li>
                    </ul>
                </div>
                <a class="nav-link-estatico" href="/Nosotros">Nosotros</a>
                <a class="nav-link-estatico" href="/Servicio">Servicios</a>
                <a class="nav-link-estatico" href="/Contacto">Contacto</a>
            </div>

            <!-- Contenedor para el logo -->
            <div class="navbar-brand">
                <a href="https://wa.me/523310301803?text=Me%20gustaria%20solicitar%20información" target="_blank">
                    <img src="/img/icons/icon-whatsapp.png" alt="WhatsApp Icon" class="whatsapp-icon">
                </a>
            </div>
        </div>
    </div>

    <div class="container-fluid separation d-none d-md-block">
    
    </div>
 </template>
 <script>
    export default {
        name: "n-estatico",
        data() {
            return {
            productos: [] 
            };
        },
        created() {

            fetch('/productos.json')
            .then(response => response.json())
            .then(data => {
                this.productos = data.productos;
            })
            .catch(error => {
                console.error('Error al cargar los productos:', error);
            });
        }
    }
 </script>
 <style scoped>
 </style>