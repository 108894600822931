<template>
  
  <!-- Carrusel para móvil -->
  <div id="carouselExampleAutoplaying" class="carousel slide mobile-carousel d-block d-md-none" data-bs-ride="carousel">
      <div class="carousel-inner">
         <div class="carousel-item active">
               <p class="mobile-banner-text">Logística&nbsp;&nbsp;<img src="/img/icons/icon-list.png" alt="List Icon" class="mobile-icons-gray-icon"></p>
         </div>
         <div class="carousel-item">
               <p class="mobile-banner-text">Distribución&nbsp;&nbsp;<img src="/img/icons/icon-truck.png" alt="Truck Icon" class="mobile-icons-gray-icon"></p>
         </div>
         <div class="carousel-item">
               <p class="mobile-banner-text">Impulso comercial&nbsp;&nbsp;<img src="/img/icons/icon-talking.png" alt="Talking Icon" class="mobile-icons-gray-icon"></p>
         </div>
         <div class="carousel-item">
               <p class="mobile-banner-text">Atención personalizada&nbsp;&nbsp;<img src="/img/icons/icon-headphone.png" alt="Headphone Icon" class="mobile-icons-gray-icon"></p>
         </div>
      </div>
   </div>
   
  <div class="d-block d-md-none">
        <nav :class="['fixed-top', 'nav-mobile', { 'bg-white': isScrolled, 'moved': hasReachedSecondSection }]" style="z-index: 1003;">
        <div class="container-fluid d-flex justify-content-between align-items-center">
          <!-- Botón para abrir el contenedor -->
            <button class="navbar-toggler" type="button" @click="toggleOffcanvas" style="padding: 20px 20px; display: flex; justify-content: center; align-items: center;">
              <i class="fa-solid fa-bars fa-xl" style="color: white;"></i>
            </button>
            <img v-if="!isScrolled" src="/img/vatrisa-logo.svg" alt="Logo" class="logo-mobile logo-center">
            <img v-else src="/img/vatrisa-logo-black.svg" alt="Logo Scrolled" class="logo-mobile logo-center">
            <a href="https://wa.me/523310301803?text=Me%20gustaria%20solicitar%20información" target="_blank">
            <img src="/img/icons/icon-whatsapp.png" alt="WhatsApp Icon" class="whatsapp-icon-mobile">
            </a>
        </div>
        </nav>

        <!-- Contenedor Offcanvas -->
        <div class="offcanvas" :class="{ 'show': isOffcanvasVisible }">
            <div class="offcanvas-header">
              <div class="offcanvas-header">
                <a type="button" class="btn-close btn-nav-estatico text-reset" @click="toggleOffcanvas" aria-label="Close">
                  <img src="/img/icon-close-movil-black.png" alt="WhatsApp Icon" class="">
                </a>
              </div>
            </div>
            <div class="offcanvas-body">
              <ul>
                <li><a href="/">Inicio</a></li>
                <li @click="toggleProductsList">
                  <a href="javascript:void(0)">Productos</a>
                </li>
                <!-- Añadir el componente de transición -->
                <transition name="fade">
                  <ul v-if="isProductsListVisible" class="sub-menu">
                    <li v-for="producto in productos" :key="producto.slug">
                      <a :href="'/producto/' + producto.slug">{{ producto.titulo_producto }}</a>
                    </li>
                    <!-- Agrega más productos según sea necesario -->
                  </ul>
                </transition>
                <li><a href="/Nosotros">Nosotros</a></li>
                <li><a href="/Servicio">Servicios</a></li>
                <li><a href="/Contacto">Contacto</a></li>
              </ul>

            </div>
          </div>

        
        
  </div>
</template>
<script>
export default {
    name: "nav-vatrisa-m",
    data() {
      return {
        isScrolled: false, // Variable para determinar si se ha hecho scroll
        hasReachedSecondSection: false, // Variable para controlar si el nav debe estar movido hacia abajo
        isOffcanvasVisible: false,
        isProductsListVisible: false, // Nueva variable para controlar la visibilidad de la lista de productos
        productos: []
      };
    },
    methods: {
      handleScroll() {
        const scrollPosition = window.scrollY;
        
        // Activar el estado de 'scroll' después de bajar un poco en la página
        this.isScrolled = scrollPosition > 30;
  
        // Obtener las coordenadas de la segunda sección
        const secondSection = document.getElementById('second-section');
        if (secondSection) {
          const sectionTop = secondSection.offsetTop; // Distancia de la segunda sección al top de la página
  
          // Si el scroll ha pasado la segunda sección, mantener el nav abajo
          if (scrollPosition >= sectionTop) {
            this.hasReachedSecondSection = true;
          }
  
          // Si el scroll vuelve a la parte superior de la página, devolver el nav a su posición original
          if (scrollPosition < sectionTop - 100) {  // Ajusta el valor (-100) según tus necesidades
            this.hasReachedSecondSection = false;
          }
        }
      },
      toggleOffcanvas() {
        this.isOffcanvasVisible = !this.isOffcanvasVisible;
      },
       toggleProductsList() {
      this.isProductsListVisible = !this.isProductsListVisible; // Alterna la visibilidad de la lista de productos
    },
    // Nuevo método para cargar los productos desde un archivo JSON usando fetch
    loadProductos() {
      fetch("/productos.json") // Reemplaza esta ruta con la correcta
        .then((response) => {
          if (!response.ok) {
            throw new Error("Error al cargar los productos");
          }
          return response.json(); // Convertir la respuesta a JSON
        })
        .then((data) => {
          this.productos = data.productos; // Guardar los productos en la variable 'productos'
        })
        .catch((error) => {
          console.error("Error al cargar los productos:", error);
        });
    }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
      this.loadProductos();
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    }
  };
  
  
document.addEventListener('DOMContentLoaded', function () {
    // Inicializa el carrusel de Bootstrap
    var myCarousel = document.querySelector('.mobile-carousel');
    var carousel = new bootstrap.Carousel(myCarousel, {
        interval: 3000, // Cambia la imagen cada 3 segundos
        wrap: true // Hace que el carrusel sea cíclico
    });
});
</script>