<template>
   <div id="nav-mob-est" class="container-fluid d-flex justify-content-between align-items-center d-block d-md-none" style="background-color: #FAFAFA; padding-top: 2vw; padding-bottom: 2vw; position: fixed; top: 0; z-index: 1003;">
    <a class="navbar-toggler bannertoggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbarB" aria-controls="offcanvasNavbarB" aria-label="Toggle navigation" style="padding: 20px 20px; display: flex; justify-content: center; align-items: center;">
        <i class="fa-solid fa-bars fa-xl" style="color: white;"></i>
    </a>
            <img src="/img/vatrisa-logo-black.svg" alt="Logo Scrolled" class="logo-mobile" onclick="window.location.href='/';">
            <a href="https://wa.me/523310301803?text=Me%20gustaria%20solicitar%20información" target="_blank">
            <img src="/img/icons/icon-whatsapp.png" alt="WhatsApp Icon" class="whatsapp-icon-mobile">
            </a>
    </div>
    <div class="container-fluid" style="height: 13vw;">
  
    </div>
    <div class="offcanvas offcanvas-start d-block d-md-none" tabindex="-1" id="offcanvasNavbarB" aria-labelledby="offcanvasNavbarLabel" style="width: 100%;">
            <div class="offcanvas-header" >
                <a type="button" class="btn-close text-reset bannertoggler" data-bs-dismiss="offcanvas" aria-label="Close" style="color: white; background-color: black;">

                  <img src="/img/icon-close-movil-black.png" alt="WhatsApp Icon" class="">

                </a>
            </div>
            <div class="offcanvas-body">
                <ul>
                    <li><a href="/" class="color-li">Inicio</a></li>
                    <li @click="toggleProductsList">
                        <a href="javascript:void(0)" class="color-li">Productos</a>
                    </li>
                     <!-- Añadir el componente de transición para desplegar la lista de productos -->
                    <transition name="fade">
                        <ul v-if="isProductsListVisible" class="sub-menu">
                        <li v-for="producto in productos" :key="producto.slug">
                            <a :href="'/producto/' + producto.slug">{{ producto.titulo_producto }}</a>
                        </li>
                        </ul>
                    </transition>
                    <li><a href="/Nosotros"  class="color-li">Nosotros</a></li>
                    <li><a href="/Servicio"  class="color-li">Servicios</a></li>
                    <li><a href="/Contacto"  class="color-li">Contacto</a></li>

                </ul>
            </div>
        </div>
 </template>
 <script>
    export default {
  name: "n-estatico-mobile",
  data() {
    return {
      isOffcanvasVisible: false,
      isProductsListVisible: false, // Controla la visibilidad de la lista de productos
      productos: [] // Variable para almacenar los productos cargados
    };
  },
  methods: {
    toggleOffcanvas() {
      this.isOffcanvasVisible = !this.isOffcanvasVisible; // Alterna la visibilidad del offcanvas
    },
    toggleProductsList() {
      this.isProductsListVisible = !this.isProductsListVisible; // Alterna la visibilidad de la lista de productos
    },
    // Método para cargar los productos desde un archivo JSON usando fetch
    loadProductos() {
      fetch("/productos.json") // Asegúrate de reemplazar esta ruta con la correcta
        .then((response) => {
          if (!response.ok) {
            throw new Error("Error al cargar los productos");
          }
          return response.json(); // Convertir la respuesta a JSON
        })
        .then((data) => {
          this.productos = data.productos; // Guardar los productos en la variable 'productos'
        })
        .catch((error) => {
          console.error("Error al cargar los productos:", error);
        });
    }
  },
  mounted() {
    this.loadProductos(); // Cargar los productos al montar el componente
  }
};

 </script>
 <style scoped>
 </style>