<template>
  <footer class="footer-broder-mobile">
      <div class="container-fluid footer footer-desktop">
        <div class="row">
            <div class="col text-left">
                <div class="line-footer"></div>
                <a href="https://maps.app.goo.gl/Vgj1CeNF7ABG1An69" target="_blank" style="text-decoration: none;">
                  <h4>UBICACIÓN <i class="fa-solid fa-location-dot fa-xs"></i></h4>
                  <p>Calle 8 No.2746, Zona Industrial, Guadalajara Jalisco México</p>
                </a>
            </div>
            <div class="col text-center">
              <a href="#top" class="arrow-circle" id="goTop">
                <i class="fas fa-chevron-up arrow-icon"></i>
              </a>
            </div>
            <div class="col text-left">
              <div class="line-footer"></div>
              <h4>CONTACTO <i class="fa-solid fa-phone fa-xs"></i></h4>
              <div class="row contact-info-footer">
                  <div class="col-sm-3">
                    <a href="tel:+523338124247" target="_blank" style="text-decoration: none;">
                      <div class="inner-box-footer">33.3812.4247</div>
                    </a>
                  </div>
                  <div class="col-sm-1" style="margin-left: -8%;">
                      <div class="inner-box-footer">|</div>
                  </div>
                  <div class="col-sm-3" style="margin-left: -4%;">
                    <a href="tel:+523338118242" target="_blank" style="text-decoration: none;">
                      <div class="inner-box-footer">33.3811.8242</div>
                    </a>
                  </div>
              </div>
              <p>Lunes a Viernes 9:00 a 18:00 hrs.</p>
            </div>
        </div>
    </div>
    <div class="container-fluid footer-last-section">
      <div class="container-fluid">
          <div class="row align-items-center">
              <div class="col-2 text-left">
                  Aviso de privacidad
              </div>
              <div class="col-8 text-center bg-column">
                  © Todos los derechos reservados VATRISA IMPULSORA COMERCIAL
              </div>
              <div class="col-2 text-right">
                <a href="https://www.zipvisual.com" target="_blank" rel="noopener noreferrer" style="text-decoration: none; color: inherit;">
                  <img src="/img/Logo_dev.png" alt="Desarrollado por ZIP" class="logo-dev">
                </a>
              </div>
          </div>
      </div>
    </div>

    <div class="footer-section d-block d-md-none">
      <div class="line-footer-mobile"></div>
      <h4>UBICACIÓN <i class="fa-solid fa-location-dot fa-xs"></i></h4>
      <a href="https://maps.app.goo.gl/Vgj1CeNF7ABG1An69" target="_blank" style="text-decoration: none; ">
         <p style="margin-top: -5vw;">Calle 8 No.2746, Zona Industrial, Guadalajara Jalisco México</p>
      </a>
    </div>
    
    <div class="footer-section d-block d-md-none">
      <div class="line-footer-mobile"></div>
      <h4>CONTACTO <i class="fa-solid fa-phone fa-xs"></i></h4>
    </div>
    <div class="footer-section d-block d-md-none" style="display: inline-block; margin-bottom: -3.8vw; margin-top: -1vw;">
      <a href="tel:+523338124247">33.3812.4247 | </a>
      <a href="tel:+523338118242">33.3811.8242</a>
    </div>
    <div class="footer-section d-block d-md-none" >
      <p>Lunes a Viernes 9:00 a 18:00 hrs.</p>
    </div>
    <div class="d-flex justify-content-between align-items-center footer-section d-block d-md-none" style="padding-right: 5%; margin-top: 10vw;">
    <p id="p3">Aviso de privacidad</p> 
    <a href="#top" class="" id="goTop">
      <img style="width: 70%;" src="/img/flecha-footer.png" alt="Vista a la derecha" class="flecha-mov">

    </a>
</div>

    <div class="footer-section d-block d-md-none" style="background-color: #EC2227; width: 100%; margin-bottom: -0.4vw; padding: 5%;">
      <p id="p2">© Todos los derechos reservados<br> VATRISA IMPULSORA COMERCIAL</p>
    </div>
    <div class="footer-section d-block d-md-none" id="logomov" style="background-color: white; margin-top: 0; margin-bottom: 0;">
      <a href="https://www.zipvisual.com" target="_blank" rel="noopener noreferrer" style="text-decoration: none; color: inherit;">
         <img src="/img/Logo_dev.png" alt="Desarrollado por ZIP" class="logo-dev-mobile">
      </a>
    </div>

  </footer>
  

</template> 

<script>
export default {
  name: "footer-component",
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&family=Montserrat:wght@400;500&display=swap');


@media (max-width: 768px) {
  .footer-last-section , .footer-desktop {
    display: none;
  }

  footer {
    background-color: #54565A;
    padding-top: 5%;
  }

  .footer-section {
    margin-bottom: 10px;
    text-align: left;
    margin-top: 5vw;
    
  }

  .footer-section p {
    margin: 0;
    font-size: 4.5vw;
    color: #8e8f92ff;
    font-family: 'Montserrat', sans-serif;
    margin-left: 4vw;
    margin-bottom:5vw;
  }

  #p3{
    margin-top: 5vw;
   color: #fff;
   font-weight: 100;
  }

  #p2 {
    margin: 0;
    font-size: 3.5vw;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    margin-left: 4vw;
    text-align: center;
  }

  .footer-section h4 {
    font-family: 'Raleway', sans-serif;
    font-size: 7vw;
    margin-left: 4vw;
  }



  .footer-section a {
    font-size: 4.5vw;
    color: #8e8f92ff;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    margin-left: 4vw;
  }
  .line-footer-mobile {
    height: 0.5vw; /* Grosor de la línea */
    background-color: white; /* Color de la línea */
    width: 70%; /* Ancho de la línea (puedes ajustarlo a lo que necesites) */
    text-align: left;
    margin-left: 4vw;
    margin-bottom: 2vw;
  }

  .logo-dev-mobile{
    width: 30%;
    height: auto;
    padding-top: 4%;
    padding-bottom: 4%;
   
  }

  #logomov{
    align-items: center;
    text-align: center;
  }
}

.footer {
    background-color: #54565A; /* Color de fondo */
    padding: 20px; /* Espaciado interno */
}


.footer p , footer h4 {
  color: white;
}

.footer h4 {
  font-size: 1.5vw;
  font-family: 'Raleway', sans-serif;
}

.footer p {
  font-size: 0.9vw;
  font-family: 'Montserrat', sans-serif;

}
.row {
    display: flex; /* Usar flexbox para la fila */
}

.col {
    flex: 1; /* Cada columna toma el mismo espacio */
    padding: 10px; /* Espaciado interno en cada columna */
}

.col-sm-3,.col-sm-1{
  padding: 0%;
  font-size: 0.9vw;
}

.contact-info-footer{
  margin-left: -38%;
}

/* Alineación de texto */
.text-left {
    text-align: left; /* Alinear a la izquierda */
    padding-left: 5%;
}
.inner-box-footer {
    margin: 0px; /* Espaciado externo entre cajas */
    text-align: left; /* Centrar texto dentro de las cajas */
    padding-left: 0vw;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9;
    color: white;
}

.arrow-circle {
    display: inline-flex; /* Cambiado de inline-block a inline-flex para mantener el alineamiento flex */
    border-radius: 50%;
    width: 3vw;
    height: 3vw;
    align-items: center; /* Centrar verticalmente */
    justify-content: center; /* Centrar horizontalmente */
    border: 0.2vw solid white;
    background-color: transparent;
}

.arrow-icon {
    font-size: 24px;
    color: white;
}

.line-footer {
    width: 60%; /* Ajusta el ancho según tus necesidades */
    height: 0.1vw; /* Grosor de la línea */
    background-color: white; /* Color de la línea */
    margin-bottom: 2%;
}

.footer-last-section {
    border-top: 0.3vw solid #808080; /* Borde superior */
    border-bottom: 0.3vw solid #EC2227; /* Borde inferior */
    padding: 1vw; /* Espaciado interno, ajustable */
    background-color: #54565A;
    
}

.footer-last-section .col-2 , .footer-last-section .col-8{
  color: white;
  font-size: 0.8vw;
  font-family: 'Raleway', sans-serif;
} 

.logo-dev{
  width: 50%;
  height: auto;
  margin-right: 20%;
}


.bg-column {
    background-color: #EC2227; /* Cambia el color a lo que desees */
    padding-bottom: 1%;
    padding-top: 1%;
    margin-bottom: -1%;
}

</style>