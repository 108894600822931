<template>
    <nav v-if="!isMobile" class="navbar fixed-top" :class="{ 'scrolled': isScrolled, 'moved-down': !isFixedTop, 'navbar-visible': isNavbarVisible }">
      <div class="container-fluid d-flex align-items-center justify-content-between">
          <!-- Contenedor para el logo -->
          <div class="navbar-brand">
              <a href="#">
                  <img v-if="!isScrolled" src="/img/vatrisa-logo.svg" alt="Logo" class="logo">
                  <img v-else src="/img/vatrisa-logo-black.svg" alt="Logo Scrolled" class="logo">
              </a>
          </div>

          <!-- Contenedor para los enlaces (centrados) -->
          <div class="nav-links d-flex justify-content-center flex-grow-1">
              <a class="nav-link" href="/">Inicio</a>
              <div class="nav-link dropdown">
                  <a href="#" class="nav-link">Productos</a>
                  <!-- Submenú Desplegable -->
                  <ul class="dropdown-menu">
                      <li v-for="producto in productos" :key="producto.slug">
                      <a :href="`/producto/${producto.slug}`">{{ producto.titulo_producto }}</a>
                      </li>
                  </ul>
              </div>
              <a class="nav-link" href="/Nosotros">Nosotros</a>
              <a class="nav-link" href="/Servicio">Servicios</a>
              <a class="nav-link" href="/Contacto">Contacto</a>
          </div>

          <!-- Contenedor para el ícono de WhatsApp (alineado a la derecha) -->
          <a href="https://wa.me/523310301803?text=Me%20gustaria%20solicitar%20información" class="whatsapp-container" target="_blank">
              <img src="/img/icons/icon-whatsapp.png" alt="WhatsApp Icon" class="whatsapp-icon">
          </a>
      </div>
  </nav>


</template>
  
  
<script>
import navVatrisaLogic from '../js/app.js';

export default {
  name: "nav-vatrisa",
  mixins: [navVatrisaLogic], 
  data() {
    return {
      productos: [] 
    };
  },
  created() {

    fetch('/productos.json')
      .then(response => response.json())
      .then(data => {
        this.productos = data.productos;
      })
      .catch(error => {
        console.error('Error al cargar los productos:', error);
      });
  }
};
</script>
  