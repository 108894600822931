export default {
    data() {
        return {
            isScrolled: false, // Estado para saber si se ha desplazado
            isFixedTop: true, // Estado para saber si se debe mantener la clase 'fixed-top'
            isNavbarVisible: false,
            isMobile: false // Agrega esta propiedad
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll); // Escuchar el evento scroll
        // Esperar que la página se cargue antes de cambiar la opacidad del navbar
        window.addEventListener('load', () => {
            this.isNavbarVisible = true; // Cambia a true cuando la página se carga
        });
        this.checkIfMobile(); // Verifica si es móvil al montar
        window.addEventListener('resize', this.checkIfMobile); // Agrega el listener para el resize
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll); // Limpiar el evento al destruir
        window.removeEventListener('resize', this.checkIfMobile); // Limpia el listener al destruir

    },
    methods: {
        handleScroll() {
            const secondSection = document.getElementById('second-section');
            if (secondSection) {
                const secondSectionTop = secondSection.getBoundingClientRect().top + window.scrollY;
                const navbarHeight = this.$el.clientHeight;

                if (window.scrollY > 0) {
                    this.isScrolled = true;
                } else {
                    this.isScrolled = false;
                }

                if (window.scrollY >= secondSectionTop - navbarHeight) {
                    this.isFixedTop = false; // Remueve la clase 'fixed-top'
                } else {
                    this.isFixedTop = true; // Agrega la clase 'fixed-top'
                }
            }
        },
        checkIfMobile() {
            this.isMobile = window.innerWidth <= 768; // Cambia el ancho según tus necesidades
        }
    }
};




