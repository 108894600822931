<template>
    <div class="d-block d-md-none">
       <!-- Carrusel para móvil -->
        <div id="carouselExampleAutoplaying" class="carousel slide mobile-carousel-estatico d-block d-md-none" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <p class="mobile-banner-text">Logística&nbsp;&nbsp;<img src="/img/icons/icon-list.png" alt="List Icon" class="mobile-icons-gray-icon"></p>
                </div>
                <div class="carousel-item">
                    <p class="mobile-banner-text">Distribución&nbsp;&nbsp;<img src="/img/icons/icon-truck.png" alt="Truck Icon" class="mobile-icons-gray-icon"></p>
                </div>
                <div class="carousel-item">
                    <p class="mobile-banner-text">Impulso comercial&nbsp;&nbsp;<img src="/img/icons/icon-talking.png" alt="Talking Icon" class="mobile-icons-gray-icon"></p>
                </div>
                <div class="carousel-item">
                    <p class="mobile-banner-text">Atención personalizada&nbsp;&nbsp;<img src="/img/icons/icon-headphone.png" alt="Headphone Icon" class="mobile-icons-gray-icon"></p>
                </div>
            </div>
        </div>

    </div>
 </template>
 <script>
    export default {
        name: "b-estatico-mobile"
    }
 </script>
 <style scoped>
 </style>